import React from "react";
import "../styles/Gaming.css";
const G = require("../assets/Gamingclub-imgs/G.png");
const A = require("../assets/Gamingclub-imgs/A.png");
const M = require("../assets/Gamingclub-imgs/M.png");
const I = require("../assets/Gamingclub-imgs/I.png");
const N = require("../assets/Gamingclub-imgs/N.png");
const GameTeam = require("../assets/Gamingclub-imgs/GameTeam.png");
const instagram = require("../assets/instagram-logo.png");
const gmail = require("../assets/gmail-logo.png");
const call = require("../assets/call-logo.png");
const gamingLogo = require("../assets/gaming-logo.png");

function Gaming() {
  return (
    <>
      <div className="gaming-section">
        <div className="gaming-head">
          <div>
            <img className="g-box" src={String(G)} />
          </div>
          <div>
            <img className="g-box" src={String(A)} />
          </div>
          <div>
            <img className="g-box" src={String(M)} />
          </div>
          <div>
            <img className="g-box" src={String(I)} />
          </div>
          <div>
            <img className="g-box" src={String(N)} />
          </div>
          <div>
            <img className="g-box" src={String(G)} />
          </div>
        </div>
        <div className="gaming-content">
          <div>
            <img className="" src={String(GameTeam)} />
          </div>
          <div>
            <h3 className="gaming-h2">
              “Creating a fun experience for students and visitors at IIT
              Madras”
            </h3>
            <p>
              Previously known as E-sports Club, the gaming club is a part of
              the Sangam sphere where competitive tournaments are conducted for
              games such as FIFA, Valorant, BGMI, Free Fire, Clash Royale,
              Brawlstars and more to come in the midst of the whole semester,
              Litsoc and Saarang. Casual Gaming, PS4 and VR stalls are set up on
              playones nights weekly/monthly for Leisure Activities. the Club
              also welcomes esports enthusiasts for watchparties and to be a
              part of the gaming contingent to cherish and represent their
              college in the tournaments across the Country.
            </p>
          </div>
          <div>
            <img className="" src={String(GameTeam)} />
          </div>
        </div>
      </div>
      <footer className="clubs-footer">
        <img
          src={String(gamingLogo)}
          alt="gamingLogo"
          style={{ marginRight: "50px" }}
        />
        <h2>Contact us at:</h2>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/gamingiitm/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(instagram)} alt="instagram Logo" />
            </a>
          </li>
          <li>
            <a
              href="mailto:esports@smail.iitm.ac.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(gmail)} alt="gmail Logo" />
            </a>
          </li>
          <li>
            <a href="tel:7973098889" target="_blank" rel="noopener noreferrer">
              <img src={String(call)} alt="call Logo" />
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}

export default Gaming;
