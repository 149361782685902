import React from 'react';
import './chronicles.css';
import img1 from '../assets/1.png'; 
import img2 from '../assets/2.jpg'; 
import img3 from '../assets/3.png';

function Chronicles() {
  return (
    <div className='chronicles-container'>
      <div className='chronicles-heading'>
        <h1 className='chronicle-title'>Sangam Chronicles</h1>
        <p className='chronicle-subtitle'>Here’s where lives of Sangam find expression, where stories unfold...</p>
      </div>

      <div className='chronicles-grid'>
        <div className='chronicle-item'>
          <img src={img1} alt='Person' className='chronicle-img'/>
          
        </div>

        <div className='chronicle-item middle'>
          <img src={img2} alt='People' className='chronicle-img'/>
          <div className='read-more'>
        <a href="#contingent"><button className='read-more-btn'>READ MORE</button></a>
      </div>
        </div>

        <div className='chronicle-item'>
          <img src={img3} alt='Group' className='chronicle-img'/>
          
        </div>
      </div>

      
    </div>
  );
}

export default Chronicles;
