import React from 'react';
import './contingent.css';
import contingentImg1 from '../assets/contingent-updates.png';
import contingentImg2 from '../assets/upcoming-fests.jpeg';
import contingentImg3 from '../assets/hall-of-fame.jpeg';
import Chronicles from './chronicles';
import Since from './since';

function Contingent() {
  return (
    <div>
      <Chronicles />
      {/* <Since/> */}
      <div className='contingent-container'>
        <h1 id='contingent'>CONTINGENT</h1>

        <div className='contingent-grid'>
          <div className='contingent-item'>
            <img src={contingentImg1} alt='Contingent Updates' className='contingent-img' />
            <h2>CONTINGENT UPDATES</h2>

          </div>
          <div className='contingent-item'>
            <img src={contingentImg2} alt='Upcoming Fests' className='contingent-img' />
            <h2>UPCOMING FESTS</h2>
          </div>
          <div className='contingent-item'>
            <img src={contingentImg3} alt='Hall of Fame' className='contingent-img' />
            <h2>HALL OF FAME</h2>

          </div>
        </div>
        <div className="text"><p>Current contingents<br />Contingent book of record</p></div>
      </div>
    </div>
  );
}
	

export default Contingent;
