import React from 'react';
import './last.css';
import instagramLogo from '../assets/instagram.png';

function Last() {
  return (
    <div className="last-container">
      <div className="last-background-text">SANGAM</div>
      <div className="info-container">
        <div className="info-left">
          <h1 className='last-title'>Connect
          to what counts</h1>
          <h2 className='last-subtitle'>Let's get started</h2>
        </div>
        <div className="info-right">
          <div className="contact-info">
            <div className="email">
              <h3 className="pink-heading">EMAIL</h3>
              <p className='last-p'><a href="mailto:events@saarang.org">events@saarang.org</a></p>
            </div>
            <div className="social">
              <h3 className="pink-heading">SOCIAL</h3>
              <a href="https://www.instagram.com/sangam_iitm" target="_blank" rel="noopener noreferrer">
                <img src={instagramLogo} alt="Instagram" className="instagram-logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Last;
