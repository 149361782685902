import React from "react";
import "../styles/DnV.css";
const dnvImg = require("../assets/dnv-img.png");
const dnvHead = require("../assets/DnV-head.png");
const instagram = require("../assets/instagram-logo.png");
const gmail = require("../assets/gmail-logo.png");
const call = require("../assets/call-logo.png");
const dnvLogo = require("../assets/dnv-logo.png");
function DnV() {
  return (
    <>
      <div className="dnv-section">
        <div className="dnv-head">
          <img src={String(dnvHead)} />
        </div>
        <div className="dnv-img">
          <div>
            <img src={String(dnvImg)} />
            <p>"Turning dreams into dazzling visuals."</p>
          </div>
        </div>
        <div className="dnv-text">
          <p>
            In the context of the Designing, animation, character design, and
            multimedia industries, the Design and VFX Club typically focuses on
            various facets of design and visual effects. The club's members
            participate in workshops, seminars, and practical projects to
            develop their abilities in 3D modeling, animation, special effects,
            and graphic design. In order to display the skills of its members
            and encourage cooperation among students with an interest in the
            topic, the club may also host events and competitions. The Design
            and VFX Club at IIT Madras seeks to establish a vibrant and active
            community of aspiring visual artists and designers through such
            events.
          </p>
        </div>
        <div className="dnv-plain">
          <img
            src={String(dnvLogo)}
            alt="dnvLogo"
          />
        </div>
      </div>
      <footer className="clubs-footer">
        <h2>Contact us at:</h2>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/designandvfxclub_iitm?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(instagram)} alt="instagram Logo" />
            </a>
          </li>
          <li>
            <a
              href="mailto:designvfx@smail.iitm.ac.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(gmail)} alt="gmail Logo" />
            </a>
          </li>
          <li>
            <a href="tel:9030807215" target="_blank" rel="noopener noreferrer">
              <img src={String(call)} alt="call Logo" />
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}

export default DnV;
