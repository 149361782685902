import React from "react";
import "../styles/Quiz.css";
const qImg1 = require("../assets/Quizclub/q-img1.png");
const qImg2 = require("../assets/Quizclub/q-img2.png");
const qImg3 = require("../assets/Quizclub/q-img3.png");
const instagram = require("../assets/instagram-logo.png");
const gmail = require("../assets/gmail-logo.png");
const call = require("../assets/call-logo.png");
const quizLogo = require("../assets/quiz-logo.png");
function Quiz() {
  return (
    <>
      <div className="quiz-section">
        <h1 className="quiz-h1">QUIZ CLUB</h1>
        <div className="quiz-content">
          <div className="q-img1">
            <img src={String(qImg1)} />
          </div>
          <div className="q-img2">
            <img src={String(qImg2)} />
          </div>
          <div className="q-img3">
            <img src={String(qImg3)} />
          </div>
          <div className="q-text">
            <p>
              The Quiz Club of IIT Madras is the oldest cultural club in the
              institute. Keeping the tradition of quizzing alive in insti, the
              club conducts regular meets and many events during LitSoc,
              Saarang, and Shaastra. A space for those who want to try out
              quizzing, the club participates in Nihilanth, the annual inter IIT
              and IIM quiz fest, winning it six times. Join us if you are
              interested in quizzing and want to meet other people who have
              similar interests.
            </p>
          </div>
        </div>
      </div>
      <footer className="clubs-footer">
        <img
          src={String(quizLogo)}
          alt="quizLogo"
          style={{ marginRight: "50px" }}
        />
        <h2>Contact us at:</h2>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/quizclubiitm/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(instagram)} alt="instagram Logo" />
            </a>
          </li>
          <li>
            <a
              href="mailto:quiz@smail.iitm.ac.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(gmail)} alt="gmail Logo" />
            </a>
          </li>
          <li>
            <a href="tel:6300290930" target="_blank" rel="noopener noreferrer">
              <img src={String(call)} alt="call Logo" />
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}

export default Quiz;
