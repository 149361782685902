import React from 'react';
import './goeson.css';
import galleryIcon from '../assets/gallery-icon.png'; 
import cultopediaIcon from '../assets/cultopedia-icon.png'; 
import blogIcon from '../assets/blog-icon.png'; 

function Goeson() {
  return (
    <div className='goeson-container'>
      <h1 className='goeson-title'>SANGAM GOES ON</h1>
      
      <div className='goeson-items'>
        <div className='goeson-item'>
          <img src={galleryIcon} alt='Gallery' className='goeson-img'/>
          <h2 className='goeson-h2'>GALLERY</h2>
          <p className='goeson-p'>Glimpse through Sangam’s gallery - it’s nothing short of a visual journal of the best of your Sangam experiences and events!</p>
        </div>
        
        <div className='goeson-item'>
          <img src={cultopediaIcon} alt='Cult-o-pedia' className='goeson-img goesonimg'/>
          <h2 className='goeson-h2'>CULT-O-PEDIA</h2>
          <p className='goeson-p'>Sangam not only entertains, it helps you grow. Find a repository of tutorial videos, DIY guides, and much more from our club sessions, interest groups, and workshops!</p>
        </div>
        
        <div className='goeson-item'>
          <img src={blogIcon} alt='Sangam Blog' className='goeson-img'/>
          <h2 className='goeson-h2'>SANGAM BLOG</h2>
          <p className='goeson-p'>Find first-hand accounts of the Sangam experiences of the team and the junta - as someone rightly said, stories go a long way!</p>
        </div>
      </div>
    </div>
  );
}

export default Goeson;
