import React from 'react';
import './nca.css';
import nca1 from '../assets/nca1.png';
import nca2 from '../assets/nca2.png';
import nca3 from '../assets/nca3.png';
import Nca2 from './nca2';
import NcaDesign from './ncadesign';

function nca() {
  return (
    <div>
    <div className="ncaContainer" >
      <h1 className='nca-title' id='nca'>NCA</h1>
      <div className="container">
        <div className="image-text">
          <img src={nca1} alt="image 1" className='nca-image' />
          <p className='nca-p'>LIST OF ART FORMS</p>
        </div>
        <div className="image-text">
          <img src={nca2} alt="image 1" className='nca-image' />
          <p className='nca-p'>ATTENDANCE TRACKER</p>
        </div>
        <div className="image-text">
          <img src={nca3} alt="image 1" className='nca-image' />
          <p className='nca-p'>GRADE DETAILS</p>
        </div>
      </div>
    </div>
    <Nca2 />  
{/*       <NcaDesign /> */}
    </div>
  )
}

export default nca
