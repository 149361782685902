import React from 'react'
import '../styles/Drama.css'
const DramaTeam1= require('../assets/Dramaclub/1.jpg')
const DramaTeam2 = require("../assets/Dramaclub/2.jpg");
const DramaTeam3 = require("../assets/Dramaclub/3.jpg");
const instagram = require("../assets/instagram-logo.png");
const gmail = require("../assets/gmail-logo.png");
const call = require("../assets/call-logo.png");
const dramaLogo = require("../assets/drama-logo.png");
function Drama() {
  return (
    <>
      <div className="Drama-section  roboto-regular">
        <h1 className="drama-h1">
          <span className="club-name">Drama Club</span>
        </h1>
        <p className="drama-text">
          One of the oldest clubs at IITM, the Drama Club has long provided a
          platform for theatre enthusiasts in our institute to showcase their
          passion for and prowess in the theatre arts, be it through acting,
          directing, scriptwriting or production. Through an assorted collection
          of events, including stage plays, street plays, mime, mono-acting and
          performance poetry and grand flagship events, including Playoffs and
          Stagecoach, it has built up the drama culture in IITM, which only
          continues to flourish. With an incredibly talented drama contingent as
          well as exceptional club events during Saarang, Drama Club hopes to
          spread a love for drama far beyond the reach of our institute.
        </p>

        <div className="drama-team">
          <div>
            <img src={String(DramaTeam1)} />
          </div>
          <div>
            <img src={String(DramaTeam2)} />
          </div>
          <div>
            <img src={String(DramaTeam3)} />
          </div>
        </div>
      </div>
      <footer className="clubs-footer">
        <img className='drama-logo'
          src={String(dramaLogo)}
          alt="dramaLogo"
          style={{ marginRight: "50px" }}
        />
        <h2>Contact us at:</h2>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/dramaclub_iitm/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(instagram)} alt="instagram Logo" />
            </a>
          </li>
          <li>
            <a
              href="mailto:drama@smail.iitm.ac.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(gmail)} alt="gmail Logo" />
            </a>
          </li>
          <li>
            <a
              href="tel:+91 9884822004"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(call)} alt="call Logo" />
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}

export default Drama
