import React from "react";
import "../styles/Writing.css";
const wcImg1 = require("../assets/writing-team.png");
const wcImg2 = require("../assets/writing-team2.jpeg");
const instagram = require("../assets/instagram-logo.png");
const gmail = require("../assets/gmail-logo.png");
const call = require("../assets/call-logo.png");
const writingLogo = require("../assets/writing-logo.png");
function Writing() {
  return (
    <>
      <div className="writing-section">
        <div className="writing-bg"></div>
        <div className="wc-content">
          <div className="wc-text">
            <h1>
              Writing
              <br />
              Club.
            </h1>
            <p>
              Lost in a good book and itching to discuss it with fellow
              bibliophiles? Or maybe you're crafting your own masterpiece?
              Whether you craft fantastical fiction or wield technical jargon
              like a pro, the Writing Club is your home. We host workshops to
              hone your skills, run competitions to unleash your creativity, and
              even have a massive inter-college writing fest in the works.
              During our last tenure, we even started working on our IIT's own
              anthology!!, with contributions from writers representing students
              from each and every one of us. Join the Writing Club for a
              platform to share your works and gain a supportive community.
            </p>
          </div>
          <div className="wc-img">
            <img src={String(wcImg1)} />
            <img src={String(wcImg2)} />
          </div>
        </div>
      </div>
      <footer className="clubs-footer">
        <img
          src={String(writingLogo)}
          alt="writingLogo"
          style={{ marginRight: "50px" }}
        />
        <h2>Contact us at:</h2>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/writingclub_iitm?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(instagram)} alt="instagram Logo" />
            </a>
          </li>
          <li>
            <a
              href="mailto:writing@smail.iitm.ac.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(gmail)} alt="gmail Logo" />
            </a>
          </li>
          <li>
            <a href="tel:8368712644" target="_blank" rel="noopener noreferrer">
              <img src={String(call)} alt="call Logo" />
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}

export default Writing;
