import React from "react";
import "../styles/Informals.css";
const infoImg1 = require("../assets/Informalsclub/info-img1.png");
const infoImg2 = require("../assets/Informalsclub/info-img2.png");
const infoImg3 = require("../assets/Informalsclub/info-img3.png");
const infoImg4 = require("../assets/Informalsclub/info-img4.png");
const instagram = require("../assets/instagram-logo.png");
const gmail = require("../assets/gmail-logo.png");
const call = require("../assets/call-logo.png");
function Informals() {
  return (
    <>
      <div className="inf-section">
        <h1 className="inf-h1">Informals Club</h1>
        <div className="inf-content">
          <div className="Inf-img1">
            <img src={String(infoImg1)} />
          </div>
          <div className="Inf-img2">
            <img src={String(infoImg2)} />
          </div>
          <div className="Inf-img3">
            <img src={String(infoImg4)} />
          </div>
          <div className="Inf-text" style={{display:'flex'}}>
            <p>Informals club at IIT Madras is meant to create fun loving
              college vibes by conducting games and socializer events like board
              game night, treasure hunt, cluedo and movie screenings. The club
              also organises big events like fandom night to celebrate the
              characters we admire. In other words, this club injects 'masti'
              into insti throughout the year during sangam. Moreover, we engage
              the floating crowd of saarang by creating fun games which are of
              many types. Informals have 6 verticals during saarang which
              organise games that are uniquely different from one another.
            </p>
            <img src={String(infoImg3)} style={{width:'200px'}}/>
          </div>
        </div>
      </div>
      <footer className="clubs-footer">
        <h2>Contact us at:</h2>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/informals_club_iitm/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(instagram)} alt="instagram Logo" />
            </a>
          </li>
          <li>
            <a
              href="mailto:informals@smail.iitm.ac.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(gmail)} alt="gmail Logo" />
            </a>
          </li>
          <li>
            <a href="tel:7973098889" target="_blank" rel="noopener noreferrer">
              <img src={String(call)} alt="call Logo" />
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}

export default Informals;
