import React from "react";
import "../styles/FineArts.css";
const fineImg = require("../assets/finearts-img.png");
const instagram = require("../assets/instagram-logo.png");
const gmail = require("../assets/gmail-logo.png");
const call = require("../assets/call-logo.png");
const fineartsLogo = require("../assets/finearts-logo.png");

function FineArts() {
  return (
    <>
      <div className="finearts-section">
        <div className="fine-img">
          <img src={String(fineImg)} />
        </div>
        <div className="fine-text">
          <h1>FINE ARTS.</h1>
          <p>
            Welcome to the Fine Arts Club, where creativity knows no bounds! We
            are a community of art enthusiasts dedicated to exploring the
            wonderful world of fine arts.The Fine Arts Club is a vibrant space
            for students passionate about various forms of art, including
            painting, drawing, sculpture, digital art, and more. We believe in
            the power of creativity to transform lives and bring people
            together. Our club regularly organizes workshops and fun sessions
            covering a wide range of techniques and mediums, helping to enhance
            artistic skills and discover new ones.The Fine Arts Club is more
            than just an art club; it’s a community. Connect with like-minded
            individuals, share your ideas, and build lasting friendships. Join
            us in celebrating the beauty of art and creativity. Let’s create
            something amazing together!
          </p>
        </div>
      </div>
      <footer className="clubs-footer">
        <img
          src={String(fineartsLogo)}
          alt="fineartsLogo"
          style={{ marginRight: "50px" }}
        />
        <h2>Contact us at:</h2>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/fineartsclub_iitm?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(instagram)} alt="instagram Logo" />
            </a>
          </li>
          <li>
            <a
              href="mailto:finearts@saarang.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(gmail)} alt="gmail Logo" />
            </a>
          </li>
          <li>
            <a href="tel:6300290930" target="_blank" rel="noopener noreferrer">
              <img src={String(call)} alt="call Logo" />
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}

export default FineArts;
