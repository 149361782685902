import React from "react";
import "../styles/Media.css";
const mImg1 = require("../assets/Mediaclub/media-img1.png");
const mImg2 = require("../assets/Mediaclub/media-img2.png");
const mImg3 = require("../assets/Mediaclub/media-img3.png");
const instagram = require("../assets/instagram-logo.png");
const gmail = require("../assets/gmail-logo.png");
const call = require("../assets/call-logo.png");
const quizLogo = require("../assets/quiz-logo.png");
function Media() {
  return (
    <>
      <div className="media-section">
        <div className="m-head">
          <p className="head2">Club</p>
          <p className="head1">Media</p>
        </div>
        <div className="m-imgsec">
          <div className="m-img1">
            <img src={String(mImg1)} />
          </div>
          <div className="m-img2">
            <img src={String(mImg2)} />
          </div>
          <div className="m-img3">
            <img src={String(mImg3)} />
          </div>
        </div>
        <div className="m-text">
          <p className="side-text">
            ""Where stories
            <br />
            come alive."
          </p>
          <hr className="media-hr" />
          <p className="m-about">
            The Media Club at IIT Madras, established in 2016, is the official
            photography and filmmaking team of the institute. Our involvement
            spans across a wide range of events within insti, including the
            planning, content creation, and coverage of Sangam events, as well
            as the organization of activities for Saarang. Notable works by our
            team include the "Make You Mine" and "Haaye Oye" videos, available
            on the Media Club’s YouTube page. Additionally, we proudly represent
            IIT Madras as a contingent in Inter IIT events. Membership in the
            Media Club offers invaluable opportunities to learn the fundamentals
            of filmmaking, post-production, and our recent focus on CGI. We are
            committed to sharing our expertise and experience with our members.
            Moreover, being part of the Media Club provides a unique chance to
            gain hands-on experience in event management and organization.
          </p>
        </div>
      </div>
      <footer className="clubs-footer">
        <h2>Contact us at:</h2>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/mediaclubiitm?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(instagram)} alt="instagram Logo" />
            </a>
          </li>
          <li>
            <a
              href="mailto:mediaclub@smail.iitm.ac.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(gmail)} alt="gmail Logo" />
            </a>
          </li>
          <li>
            <a href="tel:7598370812" target="_blank" rel="noopener noreferrer">
              <img src={String(call)} alt="call Logo" />
            </a>
          </li>
        </ul>
        <ul>
          <li style={{fontSize:'20px'}}>
            Convenors:
            <br />
            Balanagaraja B: na22b019@smail.iitm.ac.in
            <br />
            Karthik : Me22b001@smail.iitm.ac.in
          </li>
        </ul>
      </footer>
    </>
  );
}

export default Media;
