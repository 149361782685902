import React from 'react';
import './home.css';
import img1 from '../assets/img1.png';
import Explore from './explore';
import GoesOn from './goeson';
import Join from './join';
import Last from './last';

function Home() {
  return (
    <div>
      <div className="homeContainer">
        <div className="upper"></div>
        <div className="home-background-text">SANGAM</div>
        <div className="overlay">
          <div className="texts">
            <h1 className="home-title">SANGAM</h1>
            <p className="subtitle">A PLACE FOR EVERY FACE</p>
          </div>
          <a href="#explore"><button className="exploreButton">EXPLORE </button></a>
        </div>
        <img src={img1} alt="Triangle Shape" className="triangle-image"></img>

      </div>
      <Explore />
      <GoesOn />
{/*       <Join /> */}
      <Last />
    </div>
  );
}

export default Home;
