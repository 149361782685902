import React from 'react';
import './explore.css';
import img2 from '../assets/img2.png';
import img3 from '../assets/img3.png';
import img4 from '../assets/img4.png';
import img5 from '../assets/img5.png';
import img6 from '../assets/img6.png';




function Explore() {
  return (
    <div className="exploreContainer" id='explore'>
      <div className="explore-background-text" id='exploretext'>SANGAM</div>
      <div className="overlay">
        <h1 className="explore-title">EXPLORE YOUR ART</h1>
        <div className="images-container">
          <div className="image-item">
            <h2 className="image-heading"><a href="/clubs">CLUBS</a></h2>
            <a href="/clubs">
              <img src={img2} alt="Art 1" className="explore-image" /></a>
          </div>

          <div className="image-item">
            <h2 className="image-heading"><a href="/contingent">CONTINGENT</a></h2>
            <a href="/contingent">
              <img src={img4} alt="Art 3" className="explore-image" /></a>
          </div>

          <div className="image-item">
            <h2 className="image-heading"><a href="/nca">NCA</a></h2>
            <a href="/nca">
              <img src={img5} alt="Art 4" className="explore-image ncaimg" /></a>
          </div>

          <div className="image-item">
            <h2 className="image-heading"><a href="/incubator">INCUBATOR</a></h2>
            <a href="/incubator">
              <img src={img6} alt="Art 5" className="explore-image incimg" /></a>
          </div>

          <div className="image-item">
            <h2 className="image-heading"><a href="/">LITSOC</a></h2>
            <a href="/">
              <img src={img3} alt="Art 2" className="explore-image" /></a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Explore;