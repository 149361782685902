import React from "react";
import "../styles/Navbar.css";
const SangamLogo = require("../assets/Sangam-logo.png");
const SaarangLogo = require("../assets/Saarang-logo.jpeg");

export default function Navbar() {
  return (
    <nav className="navbar">
      <div>
        <a href="/">
          <img className="navImg1" src={String(SangamLogo)} />
        </a>
      </div>
      <div>
        <p>Sangam IIT Madras</p>
      </div>
      <div>
        <img className="navImg2" src={String(SaarangLogo)} />
      </div>
    </nav>
  );
}
