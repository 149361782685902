import React from 'react';
import './exploreclubs.css';
import ChoreoImage from '../assets/choreo.png';
import ClassicalArtsImage from '../assets/classicalarts.png';
import ComedyImage from '../assets/comedy.png';
import DramaImage from '../assets/drama.png';
import E_SportsImage from '../assets/esports.png';
import FineArtsImage from '../assets/finearts.png';
import FashionImage from '../assets/fashion.png';
import InformalsImage from '../assets/informals.png';
import MediaImage from '../assets/media.png';
import MusicImage from '../assets/music.png';
import OratoryImage from '../assets/oratory.png';
import QuizImage from '../assets/quiz.png';
import DesignVFXImage from '../assets/designandvfx.png';
import WordGamesImage from '../assets/wordgames.png';
import WritingImage from '../assets/writing.png';

function ExploreClubs() {
  return (
    <div>
      <div className="exploreclubsContainer" id='clubs'>
        <h1 className="heading-club">CLUBS</h1>
        <div className="exploreclubs-background-text" >SANGAM</div>
        <div className="explore-clubs-container">
          <div className="club-container">
            <a href="/clubs/choreo">
              <img src={ChoreoImage} alt="Choreo" className='exploreclubs-image'/>
              <p className='exploreclubs-p'>Choreo</p>
            </a>
          </div>
          <div className="club-container">
            <a href="/clubs/oratory">
              <img src={OratoryImage} alt="Oratory" className='exploreclubs-image' />
              <p className='exploreclubs-p'>Oratory</p>
            </a>
          </div>
          <div className="club-container">
            <a href="/clubs/finearts">
              <img src={FineArtsImage} alt="Fine Arts" className='exploreclubs-image'/>
              <p className='exploreclubs-p'>Fine Arts</p>
            </a>
          </div>
          <div className="club-container">
            <a href="/clubs/design-vfx">
              <img src={DesignVFXImage} alt="Design & VFX"className='exploreclubs-image' />
              <p className='exploreclubs-p'>Design & VFX</p>
            </a>
          </div>
          <div className="club-container">
            <a href="/clubs/classical">
              <img src={ClassicalArtsImage} alt="Classical Arts" className='exploreclubs-image' />
              <p className='exploreclubs-p'>Classical Arts</p>
            </a>
          </div>
          <div className="club-container">
            <a href="/clubs/drama">
              <img src={DramaImage} alt="Drama" className='exploreclubs-image'/>
              <p className='exploreclubs-p'>Drama</p>
            </a>
          </div>
          <div className="club-container">
            <a href="/clubs/fashion">
              <img src={FashionImage} alt="Fashion" className='exploreclubs-image'/>
              <p className='exploreclubs-p'>Fashion</p>
            </a>
          </div>
          <div className="club-container">
            <a href="/clubs/music">
              <img src={MusicImage} alt="Music" className='exploreclubs-image'/>
              <p className='exploreclubs-p'>Music</p>
            </a>
          </div>
          <div className="club-container">
            <a href="/clubs/comedy">
              <img src={ComedyImage} alt="Comedy" className='exploreclubs-image'/>
              <p className='exploreclubs-p'>Comedy</p>
            </a>
          </div>
          <div className="club-container">
            <a href="/clubs/gaming">
              <img src={E_SportsImage} alt="Gaming" className='exploreclubs-image'/>
              <p className='exploreclubs-p'>Gaming</p>
            </a>
          </div>
          <div className="club-container">
            <a href="/clubs/media">
              <img src={MediaImage} alt="Media" className='exploreclubs-image'/>
              <p className='exploreclubs-p'>Media</p>
            </a>
          </div>
          <div className="club-container">
            <a href="/clubs/wordgames">
              <img src={WordGamesImage} alt="Word Games" className='exploreclubs-image'/>
              <p className='exploreclubs-p'>Word Games</p>
            </a>
          </div>
          <div className="club-container">
            <a href="/clubs/informals">
              <img src={InformalsImage} alt="Informals" className='exploreclubs-image'/>
              <p className='exploreclubs-p'>Informals</p>
            </a>
          </div>
          <div className="club-container">
            <a href="/clubs/quiz">
              <img src={QuizImage} alt="Quiz" className='exploreclubs-image'/>
              <p className='exploreclubs-p'>Quiz</p>
            </a>
          </div>
          <div className="club-container">
            <a href="/clubs/writing">
              <img src={WritingImage} alt="Writing" className='exploreclubs-image'/>
              <p className='exploreclubs-p'>Writing</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExploreClubs;
