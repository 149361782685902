import React from 'react';
import './nca2.css';

function Nca2() {
  return (
    <div className="nca2Container">
      <h1 className='nca2-title'>NCA</h1>
      <div className="boxesContainer">
        <div className="box box-1">Carnatic Vocals</div>
        <div className="box box-2">Western Dance</div>
        <div className="box box-3">Dramatics</div>
        <div className="box box-4">Keyboard</div>
        <div className="box box-5">Bharatanatyam</div>
        <div className="box box-6">Design</div>
        <div className="box box-7">Fine Arts</div>
        <div className="box box-8">Guitar</div>
        <div className="box box-9">Contemporary Dance</div>
        <div className="box box-10">Creative Writing</div>
        <div className="box box-11">Filmmaking</div>
        <div className="box box-12">Western Vocals</div>
      </div>
      
    </div>
  )
}

export default Nca2;
