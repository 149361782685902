import React from "react";
import "../styles/Choreo.css";
const choreoTeam1 = require("../assets/Choreoclub/choreo-team1.png");
const choreoTeam2 = require("../assets/Choreoclub/choreo-team2.png");
const instagram = require("../assets/instagram-logo.png");
const gmail = require("../assets/gmail-logo.png");
const call = require("../assets/call-logo.png");

function Choreo() {
  return (
    <>
      <div className="choreo-section">
        <h1 className="choreo-head">Choreo Club</h1>
        <div className="choreo-content">
          <div className="img-block">
            <img
              alt="Team1"
              style={{ width: "350px" }}
              src={String(choreoTeam1)}
            />
            <img
              alt="Team1"
              style={{ width: "350px" }}
              src={String(choreoTeam2)}
            />
          </div>
          <div>
            <p className="choreo-text">
              The Choreo Club of IIT Madras is a student-run dance club that
              aims to promote and showcase various forms of dance. It is one of
              the most popular clubs on campus and has gained widespread
              recognition for its exceptional workshops like FWF (Frequently
              With FreakWen(U)C) and professional sessions like Sparks and Salsa
              workshops to provide maximum exposure to dance and its culture.
              The club explores different genres like contemporary, hip-hop,
              wacking and many more. The club also organises occasional dance
              cyphers and inter-hostel dance competitions for students to pursue
              their passion for dance within the institute.
            </p>
          </div>
        </div>
      </div>
      <footer className="clubs-footer">
        <h2>Contact us at:</h2>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/oratory_club_iitm/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(instagram)} alt="instagram Logo" />
            </a>
          </li>
          <li>
            <a
              href="mailto:oratory@smail.iitm.ac.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(gmail)} alt="gmail Logo" />
            </a>
          </li>
          <li>
            <a href="tel:7973098889" target="_blank" rel="noopener noreferrer">
              <img src={String(call)} alt="call Logo" />
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}

export default Choreo;
