import React from "react";
import "../styles/WordGames.css";
const wgImg = require("../assets/word-game.jpeg");
const wgImg2 = require("../assets/WG-club/wg-img.png");
const instagram = require("../assets/instagram-logo.png");
const gmail = require("../assets/gmail-logo.png");
const call = require("../assets/call-logo.png");
const wordgameLogo = require("../assets/wordgame.png");
function WordGames() {
  return (
    <>
      <div className="wg-section">
        <div className="wg-head">
          <h1>
            WORD
            <br />
            GAMES
            <br />
            CLUB.
          </h1>
        </div>
        <div className="wg-img1">
          <img src={String(wgImg2)} />
        </div>
        <div className="wg-img2">
          <img src={String(wgImg)} />
        </div>
        <div className="wg-text">
          <p>
            One of IITM’s older clubs, the Word Games Club is for those who find
            spending a random Thursday evening solving cryptic crosswords fun.
            WGC meets are all about informal gatherings; a haven for those who
            love the thrill of the hunt and the satisfaction of a puzzle
            perfectly solved. Currently, the club conducts events like
            Potpourri, What’s the Good Word, Spell Bee, Scrabble, and Cryptic
            Crossword. Our flagship event Crossie Open, a thrilling cryptic
            crossword competition, challenges crossie enthusiasts nationwide.
            Whether you're a seasoned solver or a curious newcomer, the Word
            Games Club has something to spark your love of language.
          </p>
        </div>
      </div>
      <footer className="clubs-footer">
        <img
          src={String(wordgameLogo)}
          alt="wordgameLogo"
          style={{ marginRight: "50px" }}
        />
        <h2>Contact us at:</h2>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/wgc_iitm?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(instagram)} alt="instagram Logo" />
            </a>
          </li>
          <li>
            <a
              href="mailto:wordgames@smail.iitm.ac.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={String(gmail)} alt="gmail Logo" />
            </a>
          </li>
          <li>
            <a href="tel:6282375267" target="_blank" rel="noopener noreferrer">
              <img src={String(call)} alt="call Logo" />
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}

export default WordGames;
