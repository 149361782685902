import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; // Import BrowserRouter from react-router-dom

import Navbar from './components/clubs/pages/Navbar';

import Home from './components/main/pages/home';
import Clubs from './components/main/pages/clubs';
import Nca from './components/main/pages/nca';
import Incubator from './components/main/pages/incubator';
import Contingent from './components/main/pages/contingent';

// Club Pages
import Choreo from './components/clubs/pages/Choreo';
import Oratory from './components/clubs/pages/Oratory';
import Classical from './components/clubs/pages/Classical';
import Drama from './components/clubs/pages/Drama';
import Comedy from './components/clubs/pages/Comedy';
import Gaming from './components/clubs/pages/Gaming';
import Informals from './components/clubs/pages/Informals';
import Quiz from './components/clubs/pages/Quiz';
import Media from './components/clubs/pages/Media';
import Fashion from './components/clubs/pages/Fashion';
import FineArts from './components/clubs/pages/FineArts';
import DnV from './components/clubs/pages/DnV';
import Music from './components/clubs/pages/Music';
import WordGames from './components/clubs/pages/WordGames';
import Writing from './components/clubs/pages/Writing';

function App() {
  return (
    <div className="App">
      <Navbar />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/clubs" element={<Clubs />} />
          {/* <Route path="/exploreclubs" element={<ExploreClubs />} /> */}

          {/* Club Routes */}
          <Route path="/clubs/choreo" element={<Choreo />} />
          <Route path="/clubs/oratory" element={<Oratory />} />
          <Route path="/clubs/classical" element={<Classical />} />
          <Route path="/clubs/drama" element={<Drama />} />
          <Route path="/clubs/comedy" element={<Comedy />} />
          <Route path="/clubs/gaming" element={<Gaming />} />
          <Route path="/clubs/informals" element={<Informals />} />
          <Route path="/clubs/quiz" element={<Quiz />} />
          <Route path="/clubs/media" element={<Media />} />
          <Route path="/clubs/fashion" element={<Fashion />} />
          <Route path="/clubs/finearts" element={<FineArts />} />
          <Route path="/clubs/design-vfx" element={<DnV />} />
          <Route path="/clubs/music" element={<Music />} />
          <Route path="/clubs/wordgames" element={<WordGames />} />
          <Route path="/clubs/writing" element={<Writing />} />


          <Route path="/nca" element={<Nca />} />
          {/* <Route path="/nca2" element={<Nca2 />} /> */}
          {/* <Route path="/ncadesign" element={<NcaDesign />} /> */}

          <Route path="/incubator" element={<Incubator />} />

          {/* <Route path="/since" element={<Since />} />
          <Route path="/chronicles" element={<Chronicles />} /> */}
          <Route path="/contingent" element={<Contingent />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
